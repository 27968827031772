import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useAppSelector } from 'redux/store';
import { validateDecimals } from 'utils/constant';

import CustomInstallments from './CustomInstallments';
import CustomLinkInstallments from './CustomLinkInstallments';

const InstallmentSelection = props => {
  const { formikProps, handleTotalOtherCharge, handleTotalExtraCharge } = props;
  const { values, setFieldValue, errors } = formikProps;

  const { booking_validation } = useAppSelector(s => s.sales);

  // const dispatch = useAppDispatch();

  // useEffect(() => {
  //   dispatch(
  //     clpPercentageSelection({
  //       project_id,
  //     }),
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const handleClassificationChange = (e: {
  //   value: unknown;
  //   details: { customer_percentage: number; bank_percentage: number };
  // }) => {
  //   setFieldValue('clp_bank_id', e.value);
  //   setFieldValue('from_customer', e.details.customer_percentage);
  //   setFieldValue('from_bank', e.details.bank_percentage);
  // };

  // const installmentClassificationOptions = useMemo(
  //   () =>
  //     clp_percentage?.map(v => ({
  //       label: v.name,
  //       value: v.id,
  //       details: v,
  //     })),
  //   [clp_percentage],
  // );

  const handleInstallmentTypeChange = (type: string) => {
    if (type === 'clp_installments') {
      setFieldValue('installment_type', type);
      setFieldValue('installments', []);
      // setFieldValue('clp_bank_id', null);
    } else {
      setFieldValue('installment_type', type);
      setFieldValue('clp_installments', []);
      // setFieldValue('clp_bank_id', 0);
      setFieldValue('from_customer', 0);
      setFieldValue('from_bank', 0);
    }
  };

  // calculate clp percentage
  const autoCalculatePercentage = () => {
    const {
      basic_rate_basic_amount = 0,
      gst_amt = 0,
      stampduty_amount = 0,
      reg_amount = 0,
      loan_amt = 0,
    } = values;

    const property_final_amount =
      (Number(basic_rate_basic_amount) || 0) +
      Number(handleTotalOtherCharge() || 0) +
      (Number(gst_amt) || 0) +
      (Number(stampduty_amount) || 0) +
      (Number(reg_amount) || 0) +
      Number(handleTotalExtraCharge() || 0);

    const bankPercent = Number(loan_amt).toFixed(2);
    const customerPercent = Number(property_final_amount - loan_amt).toFixed(2);

    setFieldValue('from_bank', bankPercent);
    setFieldValue('from_customer', customerPercent);
  };

  return (
    <div className="booking-form-box shwan-form mt-4">
      <div className="booking-form-col-12">
        <h5>Payment Schedule</h5>
        <div className="form-row ml-3">
          <div className="row align-items-center w-100">
            <p className="mb-0 mr-4">
              <label>Select Schedule Type :</label>
            </p>
            <RadioGroup row aria-labelledby="installments-selection" name="installment-selection">
              <FormControlLabel
                checked={values.installment_type === 'clp_installments'}
                className="mr-5"
                control={
                  <Radio
                    onClick={() => {
                      handleInstallmentTypeChange('clp_installments');
                    }}
                  />
                }
                label="Construction Link Plan"
                value="clp_installments"
              />
              <FormControlLabel
                checked={values.installment_type === 'custom_installments'}
                control={
                  <Radio
                    onClick={() => {
                      handleInstallmentTypeChange('custom_installments');
                    }}
                  />
                }
                label="Custom Installments"
                value="custom_installments"
              />
            </RadioGroup>
          </div>
        </div>
        {values.installment_type === 'clp_installments' &&
        booking_validation?.clp_bank_onoff !== 'no' ? (
          <div className="form-row mt-3">
            <div className="col-6">
              {/* <div className="form-row">
                <div className="col-12">
                  <Select
                    closeMenuOnSelect={true}
                    options={installmentClassificationOptions}
                    placeholder="Select Classification"
                    styles={{
                      container: base => ({
                        ...base,
                        marginTop: 10,
                        marginBottom: 20,
                        width: '25rem',
                      }),
                      control: base => ({
                        ...base,
                        borderColor: errors.clp_bank_id ? 'rgba(255, 93, 93)' : 'hsl(0, 0%, 80%)',
                      }),
                    }}
                    onChange={handleClassificationChange}
                  />
                </div>
              </div> */}
              <div
                className="booking-form-col-6 p-3"
                style={{ borderRight: 'none', backgroundColor: '#F9FAFE' }}
              >
                <label>Bifurcation of amount</label>

                <div className="form-row">
                  <div className="col-4  pr-4">
                    <label>From customer</label>
                    <input
                      readOnly
                      className="form-control"
                      name="from_customer"
                      style={{ borderColor: errors?.from_customer && 'rgba(255, 93, 93)' }}
                      type="number"
                      value={values.from_customer}
                      onChange={v => validateDecimals('from_customer', v, setFieldValue)}
                    />
                  </div>
                  <div className="col-4  pr-4">
                    <label>From bank</label>
                    <input
                      readOnly
                      className="form-control"
                      name="from_bank"
                      style={{ borderColor: errors?.from_customer && 'rgba(255, 93, 93)' }}
                      type="number"
                      value={values.from_bank}
                      onChange={v => validateDecimals('from_bank', v, setFieldValue)}
                    />
                  </div>
                  <div className="col-4">
                    <button
                      className="Btn btn-lightblue-primary lbps-btn py-2"
                      id="butng"
                      type="button"
                      onClick={autoCalculatePercentage}
                    >
                      Auto Calculate
                    </button>
                  </div>
                </div>
              </div>
              {/* <Typography
                className="mb-3
                "
              >{`From Customer : ${values.from_customer || 0} % | From Bank : ${
                values.from_bank || 0
              } %`}</Typography> */}
            </div>
          </div>
        ) : undefined}
      </div>
      {values?.installment_type ? (
        values.installment_type === 'clp_installments' ? (
          <CustomLinkInstallments {...props} />
        ) : (
          <CustomInstallments {...props} />
        )
      ) : undefined}
    </div>
  );
};

export default InstallmentSelection;
