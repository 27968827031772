/* eslint-disable @typescript-eslint/no-empty-function */
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { useFormik } from 'formik';
import { useSyncedFields } from 'hooks/useDiscountCalculator';
import Cookies from 'js-cookie';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAreaInfo } from 'redux/sales';
import { useAppDispatch, useAppSelector } from 'redux/store';

const styles = {
  labelFont: {
    fontSize: 16,
    fontWeight: 500,
    color: '#53575b',
  },
};
// const project_id = '19';
// const unit_id = '22';
const QutotationInformation = props => {
  // handleTotalExtraCharge
  const { handleTotalOtherCharge, rate, title, installmentId } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { unitAreaInfo } = useAppSelector(s => s.sales);

  const project_id = Cookies.get('_hjproject_idUser_2492391');
  const unit_id = Cookies.get('_hjunit_idUser_2492391');
  // const tower_id = Cookies.get('_hjtower_idUser_2492391');

  // form initials
  const initialValues = useMemo(() => {
    return {
      project_id,
      gst_per: 0,
      gst_amt: 0,
      basic_rate_area: unitAreaInfo?.super_build_up_area || 0,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project_id, unitAreaInfo?.super_build_up_area]);

  // Api calls
  useEffect(() => {
    dispatch(getAreaInfo({ project_id, project_main_types: 6, unit_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: () => {},
  });
  const { values, setFieldValue } = formik;

  // discount calculations hook
  const gstSyncedFields = useSyncedFields(
    Number(values.basic_rate_area * rate) + parseFloat(handleTotalOtherCharge()),
    'gst_amt',
    'gst_per',
    setFieldValue,
  );
  const handleViewClick = () => {
    navigate('/UEbookingForm2', {
      state: {
        rate,
        gstPer: values.gst_per,
        gstAmt: values.gst_amt,
        type: 'clp_base',
        installmentId,
      },
    });
  };
  return (
    <div className="card-booking-form-box shwan-form mt-4">
      <div>
        <div className="d-flex justify-content-between">
          <h5>{title}</h5>
          <button
            className="Btn btn-lightblue-primary lbps-btn"
            style={{ minWidth: 100 }}
            type="button"
            onClick={() => handleViewClick()}
          >
            <div className="d-flex aligns-items-center">
              View Details <ChevronRight />
            </div>
          </button>
        </div>

        <div className="row mt-4">
          <div className="col-12">
            <div style={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
              <div className="form-row ">
                <div className="col-4">
                  <label style={styles.labelFont}>Basic amount</label>
                </div>
                <div className="col-2">
                  <label>:</label>
                </div>
                <div className="col-4">
                  <label>
                    <span> ₹ </span>
                    <span>{values.basic_rate_area * rate}</span>
                    <span> (+)</span>
                  </label>
                </div>
              </div>
              <div className="form-row ">
                <div className="col-4">
                  <label style={styles.labelFont}>Other charges</label>
                </div>
                <div className="col-2">
                  <label>:</label>
                </div>
                <div className="col-4">
                  <label>
                    <span> ₹ </span>
                    <span> {handleTotalOtherCharge()} </span>
                    <span> (+)</span>
                  </label>
                </div>
              </div>

              <div className="form-row">
                <div className="col-4">
                  <label style={styles.labelFont}>GST</label>
                </div>
                <div className="col-2">
                  <label>:</label>
                </div>
                <div className=" col-2">
                  <input
                    className="form-control"
                    name="gst_per"
                    type="number"
                    value={values.gst_per}
                    onChange={gstSyncedFields.onChangePercent}
                  />
                </div>
                <div className=" col-2">
                  <input
                    readOnly
                    className="form-control"
                    name="gst_amt"
                    type="number"
                    value={values.gst_amt}
                    onChange={gstSyncedFields.onChangeAmount}
                  />
                </div>
              </div>
              {/* <div className="form-row ">
                <div className="col-4">
                  <label style={styles.labelFont}>Extra charges</label>
                </div>
                <div className="col-2">
                  <label>:</label>
                </div>
                <div className="col-4">
                  <label>
                    <span> ₹ </span>
                    <span> {handleTotalExtraCharge()} </span>
                    <span> (+)</span>
                  </label>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div
          className="form-row mt-4"
          style={{ backgroundColor: '#EDF1FE', borderRadius: '4px', padding: '5px 0px' }}
        >
          <div className="col-4">
            <label style={styles.labelFont}>Property Final Amount</label>
          </div>
          <div className="col-2 ">
            <label>:</label>
          </div>
          <div className="col-4">
            <label style={{ fontSize: '1.1rem' }}>
              <span> ₹ </span>
              <span>
                {Number(values.basic_rate_area * rate) +
                  parseFloat(handleTotalOtherCharge()) +
                  // parseFloat(handleTotalExtraCharge()) +
                  Number(values.gst_amt)}
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QutotationInformation;
