import './BookingForm.css';

import Loader from 'components/atoms/Loader';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getProjectPermissions } from 'redux/project';
import {
  addBooking,
  getApprovalUnitDetails,
  getAreaInfo,
  getBookingFormOwnerFlag,
  getBrokerList,
  getCLPInstallmentOptions,
  getCustomersList,
  getOtherChargesList,
  getOtherExtraCharges,
  getOwnershipInputField,
  getProjectUnitStatus,
  getUnitInfo,
  getUnitParkingInfo,
  getVisitorsList,
  parkingList,
  updateFormFillingStatus,
  updateUnitStatusToAvailable,
} from 'redux/sales';
import { IBroker, IVisitor } from 'redux/sales/salesInterface';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getPermissions } from 'utils';
import {
  ADHAAR_REGEX,
  PAN_REGEX,
  PASSPORT_REGEX,
  PERMISSIONS_BASE_STRUCTURE,
  PHONE_REGEX,
} from 'utils/constant';
import * as Yup from 'yup';

//Sub Components
import BookingHeader from './components/BookingHeader';
import BrokerDetails from './components/BrokerDetails';
import CalculationMethod from './components/CalculationMethod';
import CustomerDetails from './components/CustomerDetails';
import ExtraCharges from './components/ExtraCharges';
import GovtTaxes from './components/GovtTaxes';
import InstallmentSelection from './components/InstallmentSelection';
import Loan from './components/Loan';
import OtherCharges from './components/OtherCharges';
import OverallDiscount from './components/OverallDiscount';
import Ownership from './components/Ownership';
import Parking from './components/Parking';
import Signature from './components/Signature';
import Summary from './components/Summary';
import Terms from './components/Terms';
import UnitInfo from './components/UnitInfo';

const BookingForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const edit = false;

  // url params
  // const project_id = searchParams.get('project_id');

  // const unit_id = searchParams.get('unit_id');
  // const tower_id = searchParams.get('tower_id');
  // const floor_id = searchParams.get('project_floor');
  // const project_type = searchParams.get('project_type');
  // const pid = searchParams.get('pid'); //encrypted project id
  // const project_list_id = searchParams.get('project_list_id') || '1';
  // const project_bookings_temp_id = searchParams.get('bookingid');

  // const project_id = '2';
  // const unit_id = '50';
  // const tower_id = '1';
  // const floor_id = '1';
  // const pid =
  //   'eyJpdiI6Ik5sNXlyYUJpaWZMN2IzWHg2V1VhUUE9PSIsInZhbHVlIjoiRGx6eVhzQWRudHZxamRtXC96bHNXZlE9PSIsIm1hYyI6IjhiMDQzOTNmZDZhYjRjMDhhNmExODZlNDc4Yzg4ZDU1NDgwY2FkZjYwYTQ1ZjM1N2I2YTI4MDlkYTcyYzIzNGUifQ%3D%3D';
  // const project_list_id = '1';
  // const project_bookings_temp_id = '219';

  const project_id = Cookies.get('_hjproject_idUser_2492391');

  const unit_id = Cookies.get('_hjbookingidUser_2492391')
    ? Cookies.get('_hjpreviewUnitUser_2492391')
    : Cookies.get('_hjunit_idUser_2492391');
  const tower_id = Cookies.get('_hjbookingidUser_2492391')
    ? Cookies.get('_hjpreviewtower_idUser_2492391')
    : Cookies.get('_hjtower_idUser_2492391');
  const floor_id = Cookies.get('_hjbookingidUser_2492391')
    ? Cookies.get('_hjpreviewproject_floorUser_2492391')
    : Cookies.get('_hjproject_floorUser_2492391');
  const pid = Cookies.get('_hjpidUser_2492391');
  const project_list_id = Cookies.get('_hjproject_list_idUser_2492391') || '1';
  const project_bookings_temp_id = Cookies.get('_hjbookingidUser_2492391');

  const user_id = Cookies.get('_hjuseridUser_2492391');
  // const user_id = '45';
  // const project_bookings_temp_id = '29';
  // // old site navigation
  const OLD_SITE = `${process.env.REACT_APP_REDIRECT_URL}booking_units_new/${pid}/${project_list_id}/6/${tower_id}`;

  // Edit Id
  const EDIT = Boolean(project_bookings_temp_id);

  // redux state values
  const {
    brokerList,
    unitParkingInfo,
    otherChargesList,
    unitAreaInfo,
    extraChargesList,
    projectUnitStatus,
    booking_validation,
    approvalBookingDetails,
    parkingInfo,
    ownership_validation,
    loading,
  } = useAppSelector(s => s.sales);

  const VALIDATION_REQUIRED_OWNERSHIP =
    booking_validation?.booking_ownership === 'yes' ? true : false;

  const PERCENTAGE_CLASSIFICATION_REQUIRED =
    booking_validation?.clp_bank_onoff === 'yes' ? true : false;

  const e_SIGNATURE_REQUIRED = booking_validation?.booking_signature === 'yes' ? true : false;

  const GOVERNMENT_REQUIRED = booking_validation?.gov_mandatory_switch === 'yes' ? true : false;

  const PARKING_SWITCH = booking_validation?.parking_module_switch_on_off === 'yes' ? true : false;

  const OWNER_PHONE = ownership_validation?.customer_phone === '1';
  const OWNER_EMAIL = ownership_validation?.customer_email === '1';
  const OWNER_ADDRESS = ownership_validation?.customer_address === '1';
  const OWNER_DOB = ownership_validation?.date_of_birth === '1';
  const OWNER_AADHAR = ownership_validation?.customer_aadhar === '1';
  const OWNER_ELECTION = ownership_validation?.customer_election === '1';
  const OWNER_DL = ownership_validation?.customer_dl === '1';
  const OWNER_PAN = ownership_validation?.customer_pan === '1';

  // const oldBookingData = approvalBookingDetails?.booking_form_list;

  const [customerDetails, setCustomerDetails] = useState<IVisitor>();
  const [brokerDetails, setBrokerDetails] = useState<IBroker>();
  const [isToggle, setIsToggle] = useState(false);
  const [extraCharges, setExtraCharges] = useState([]);
  const [baseAmount, setBaseAmount] = useState<number>();
  const [OCList, setOCList] = useState([]);
  const [inquiryPermission, setInqiryPermission] = useState(PERMISSIONS_BASE_STRUCTURE);
  const [brokerPermission, setBrokerPermission] = useState(PERMISSIONS_BASE_STRUCTURE);
  const [ownershipPermission, setOwnershipPermission] = useState(PERMISSIONS_BASE_STRUCTURE);

  const { modulesData } = useAppSelector(s => s.project);

  const toggleLoanSwitch = () => {
    setIsToggle(!isToggle);
  };

  // const scrollToError = () => {
  //   errorBoxRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  // };

  // parkingInfo
  const unitParkingInfoValues = useMemo(() => {
    return unitParkingInfo?.all_parking_units?.filter(e => e.allotment_data === unit_id);
  }, [unitParkingInfo?.all_parking_units, unit_id]);

  const ParkingOption = useMemo(() => {
    return parkingInfo?.map(e => ({
      label: `${e.parking_no}`,
      value: e.id,
    }));
  }, [parkingInfo]);

  useEffect(() => {
    setInqiryPermission(getPermissions('Inquiry'));
  }, [modulesData.submodules]);
  useEffect(() => {
    setBrokerPermission(getPermissions('Broker list'));
  }, [modulesData.submodules]);
  useEffect(() => {
    setOwnershipPermission(getPermissions('Ownership'));
  }, [modulesData.submodules]);

  const { customer_first_name, customer_last_name, customer_email, customer_phone } =
    approvalBookingDetails?.booking_form_list || {};

  // form initials
  const initialValues = useMemo(() => {
    if (EDIT) {
      const {
        project_id,
        visitors_id,
        broker_id,
        brokerage,
        unit_reserved_date,
        parking_no,
        terracearea,
        super_build_up_area,
        broker_remark,
        calculation_method,
        basic_rate_no,
        basic_rate_description,
        basic_rate_area,
        basic_rate,
        basic_rate_disc_amt,
        basic_rate_disc_per,
        basic_rate_basic_amount,
        ownership,
        custom_payment_remark_id,
        custom_payment_remark,
        disc_remarks,
        is_loan,
        loan_amt,
        bank,
        loan_remarks,
        bank_id,
        property_final_amount,
        gst,
        gst_amt,
        gst_per,
        stampduty_amount,
        stampduty_per,
        reg_amount,
        reg_per,
        other_charges_total,
        sub_total_amt,
        extra_charges_total,
        total_gove_tax,
        installment_type,
        custom_payment_no,
        custom_payment_installment,
        installment_per,
        installment_basic_amt,
        installment_otherchages_amt,
        installment_amount,
        installment_due_date,
        custom_payment_total_amount,
        total_disc,
        clp_installments,
        installments,
        from_bank,
        from_customer,
        through_broker,
        signature,
        clp_bank_id,
      } = approvalBookingDetails?.booking_form_list || {};

      return {
        project_id,
        unit_id,
        visitors_id,
        broker_id,
        brokerage,
        broker_remark,
        unit_reserved_date,
        parking_no,
        terracearea,
        super_build_up_area,
        calculation_method:
          calculation_method === 'fixied_amt'
            ? 'fixed_amount'
            : calculation_method === 'clp_base'
            ? 'clp_base'
            : 'rate_base',
        basic_rate_no,
        basic_rate_description,
        basic_rate_area,
        basic_rate,
        basic_rate_disc_amt,
        basic_rate_disc_per,
        basic_rate_basic_amount,
        ownership,
        other_charges: [],
        custom_payment_remark_id,
        custom_payment_remark,
        disc_remarks,
        extra_charges: [],
        is_loan,
        bank_id,
        loan_amt,
        loan_remarks,
        bank: EDIT ? bank_id : bank,
        property_final_amount,
        gst,
        gst_amt: Number(gst_amt),
        gst_per: Number(gst_per),
        stampduty_amount: Number(stampduty_amount),
        stampduty_per: Number(stampduty_per),
        reg_amount: Number(reg_amount),
        reg_per: Number(reg_per),
        other_charges_total,
        extra_charges_total,
        sub_total_amt,
        total_gove_tax,
        custom_payment_no,
        custom_payment_installment,
        installment_type,
        installment_per,
        installment_basic_amt,
        installment_otherchages_amt,
        installment_amount,
        custom_payment_total_amount,
        installment_due_date,
        total_disc,
        installments: installments?.length ? installments : [],
        clp_installments: clp_installments?.length ? clp_installments : [],
        from_bank,
        from_customer,
        through_broker,
        signature,
        clp_bank_id,
      };
    }
    return {
      project_id,
      unit_id: unit_id,
      visitors_id: customerDetails?.id,
      broker_id: null,
      through_broker: false,
      brokerage: 0,
      broker_remark: '',
      unit_reserved_date: dayjs().format('YYYY-MM-DD'),
      parking_no: '',
      calculation_method: '',
      basic_rate_no: 1,
      basic_rate_description: 'Basic rate of unit',
      basic_rate_area: unitAreaInfo?.super_build_up_area || 0,
      basic_rate: unitAreaInfo?.rate_base_amt || 0,
      basic_rate_disc_amt: 0,
      basic_rate_disc_per: 0,
      basic_rate_basic_amount: 0,
      other_charges: [],
      other_charges_total: 0,
      other_charges_total_discount: 0,
      custom_payment_remark_id: 0,
      custom_payment_remark: '',
      disc_remarks: '',
      extra_charges: [],
      gst_per: 0,
      gst_amt: 0,
      stampduty_per: 0,
      stampduty_amount: 0,
      reg_per: 0,
      reg_amount: 0,
      total_gove_tax: 0,
      is_loan: 'yes',
      loan_amt: 0,
      bank: 0,
      bank_id: 0,
      loan_remarks: '',
      custom_payment_total_amount: 0,
      extra_charges_total: 0,
      ownership: [],
      installment_type: '',
      installments: [],
      clp_installments: [],
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    EDIT,
    project_id,
    unitAreaInfo?.rate_base_amt,
    unitAreaInfo?.super_build_up_area,
    unitParkingInfoValues,
    unit_id,
  ]);

  // Api calls
  useEffect(() => {
    dispatch(
      getVisitorsList({
        project_id,
        filter_mode: 'name',
        role: 'admin',
        page: 'all',
      }),
    );
    dispatch(getUnitInfo({ project_id, tower_id }));
    dispatch(getUnitParkingInfo({ project_id }));
    dispatch(getCustomersList({ project_id }));
    dispatch(getBrokerList({ project_id }));
    dispatch(getOtherChargesList({ project_id, unit_id }));
    dispatch(getOtherExtraCharges({ project_id, unit_id }));
    dispatch(getAreaInfo({ project_id, project_main_types: 6, unit_id }));
    dispatch(getBookingFormOwnerFlag({ project_id }));
    dispatch(
      getCLPInstallmentOptions({ project_id: Number(project_id), tower_id: Number(tower_id) }),
    );
    if (!EDIT) {
      dispatch(updateFormFillingStatus({ project_id, unit_id }));
    }
    dispatch(getApprovalUnitDetails({ project_id, project_bookings_temp_id, unit_id }));
    dispatch(
      getProjectPermissions({
        project_id,
      }),
    );
    dispatch(parkingList({ project_id, unit_id, type: EDIT ? 'edit' : '' }));
    dispatch(getOwnershipInputField({ project_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      getProjectUnitStatus({
        project_id,
        project_type: '6',
        project_tower: tower_id,
        project_floor: floor_id,
        id: project_list_id,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateFormFillingStatus]);

  // Other Charges list populate
  useEffect(() => {
    handleMapOtherCharges();
    handle_Other_Charge_Row_Total();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherChargesList, unitAreaInfo, extraChargesList]);

  // Extra Charges list populate
  useEffect(() => {
    handleUpdateExtraCharges();
    handle_Extra_Charge_Row_Total();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraChargesList, otherChargesList, unitAreaInfo]);

  // if inquiry contains broker auto selected
  useEffect(() => {
    const broker_id = approvalBookingDetails?.booking_form_list?.broker_id;
    const brokerID =
      !isNaN(broker_id) || broker_id === 0 || customerDetails?.brokers_id
        ? approvalBookingDetails?.booking_form_list?.broker_id || customerDetails?.brokers_id
        : 0;
    if (brokerID !== 0 && brokerID !== null && brokerID !== undefined) {
      setFieldValue('broker_id', brokerID);
      setFieldValue('through_broker', true);

      const brokerDetails = brokerList?.find(e => e.id === brokerID);
      setBrokerDetails(brokerDetails);
    } else {
      setFieldValue('through_broker', false);
      setFieldValue('broker_id', null);
      setBrokerDetails(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brokerList, customerDetails]);

  // other charges update, delete
  function handleMapOtherCharges() {
    const OC = EDIT
      ? approvalBookingDetails?.booking_form_list?.othercharge
      : otherChargesList.other_charge_unit_rates;
    const updatedData = OC?.map(x => ({
      amount_type: EDIT ? x.amount_type : x?.amount_type,
      fixed_amounts: EDIT ? Number(x.fixed_amounts) : Number(x.fixed_amounts) || 0,
      ratebase_amounts: EDIT ? Number(x.ratebase_amounts) : Number(x.ratebase_amounts) || 0,
      other_charges_no: EDIT ? x.other_charges_no : x.id,
      other_charges_title: EDIT ? x.other_charges_title : x.title,
      other_charges_distribution_method: EDIT ? x.other_charges_distribution_method : '',
      other_charges_area: EDIT ? x.other_charges_area : x.area || 0,
      other_charges_rate: EDIT
        ? x.other_charges_rate
        : x.amount_type === 'ratebase_amt'
        ? Number(x.ratebase_amounts)
        : Number(x.fixed_amounts),
      other_charges_disc_amt: EDIT ? x.other_charges_disc_amt : 0,
      other_charges_disc_per: EDIT ? x.other_charges_disc_per : 0,
      other_charges_amount: EDIT ? Number(x.other_charges_amount) : 0,
      other_charges_base: 0,
    }));

    setOCList(updatedData);
  }

  const handleTotalOtherCharge = useCallback(() => {
    let total = 0;

    OCList?.forEach(charge => {
      total += parseFloat(charge?.other_charges_amount) || 0;
    });
    return Number(total).toFixed(2);
  }, [OCList]);

  const handleTotalOtherDiscountAmt = useCallback(() => {
    let total = 0;
    OCList?.forEach(charge => {
      total += parseFloat(charge?.other_charges_disc_amt) || 0;
    });
    return Number(total).toFixed(2);
  }, [OCList]);

  function handle_Other_Charge_Row_Total() {
    OCList?.map((x, index) => {
      const calculatedAmount = EDIT
        ? x.other_charges_area > 0
          ? Number(x.other_charges_area) * x.other_charges_rate
          : Number(x.other_charges_rate)
        : x.amount_type === 'ratebase_amt'
        ? Number(x.other_charges_area) * x.other_charges_rate
        : x.fixed_amounts;

      let otherChargesAmount;
      if (EDIT) {
        otherChargesAmount =
          x.other_charges_distribution_method === 0 || x.other_charges_distribution_method === '0'
            ? 0
            : calculatedAmount.toFixed(2) - x.other_charges_disc_amt;
      } else {
        otherChargesAmount =
          x.other_charges_distribution_method === ''
            ? 0
            : calculatedAmount.toFixed(2) - x.other_charges_disc_amt;
      }

      setOCList(prevList => {
        const newUnitRates = [...prevList];
        newUnitRates[index] = {
          ...newUnitRates[index],
          other_charges_amount: otherChargesAmount,
        };
        return newUnitRates;
      });
    });
  }

  const handleOCListChange = (index, field, value) => {
    setOCList(prevList => {
      const newUnitRates = [...prevList];
      newUnitRates[index] = {
        ...newUnitRates[index],
        [field]: value,
      };

      const OC_ROW_BASE_AMT = EDIT
        ? newUnitRates[index].other_charges_area > 0
          ? newUnitRates[index].other_charges_area * newUnitRates[index].other_charges_rate
          : newUnitRates[index].other_charges_rate
        : newUnitRates[index].amount_type === 'ratebase_amt'
        ? newUnitRates[index].other_charges_area * newUnitRates[index].other_charges_rate
        : newUnitRates[index].fixed_amounts;
      const discountAmt = newUnitRates[index].other_charges_disc_amt;
      const totalAmount = OC_ROW_BASE_AMT === 0 ? 0 : Number(OC_ROW_BASE_AMT) - Number(discountAmt);
      newUnitRates[index].other_charges_amount = totalAmount.toFixed(2);

      return [...newUnitRates];
    });
  };

  // extra charges update, delete & add
  function handleUpdateExtraCharges() {
    const EC = EDIT
      ? approvalBookingDetails?.booking_form_list?.extracharges
      : extraChargesList?.other_charge_unit_rates;

    const updatedData = EC?.map(x => ({
      amount_type: EDIT ? x?.amount_type : x?.amount_type,
      fixed_amounts: EDIT ? Number(x.fixed_amounts) : Number(x.fixed_amounts) || 0,
      ratebase_amounts: EDIT ? Number(x.ratebase_amounts) : Number(x.ratebase_amounts) || 0,
      title: EDIT ? x.extra_charges_title : x.title,
      extra_charges_no: EDIT ? x.extra_charges_no : x.id,
      extra_charges_title: EDIT ? x.extra_charges_title : x.title,
      extra_charges_distribution_method: EDIT ? x.extra_charges_distribution_method : '',
      extra_charges_area: EDIT ? x.extra_charges_area : x.area || 0,
      extra_charges_rate: EDIT
        ? x.extra_charges_rate
        : x.amount_type === 'ratebase_amt'
        ? Number(x.ratebase_amounts)
        : Number(x.fixed_amounts),
      extra_charges_disc_amt: EDIT ? x.extra_charges_disc_amt : 0,
      extra_charges_disc_per: EDIT ? x.extra_charges_disc_per : 0,
      extra_charges_amt: EDIT ? x.extra_charges_amt : 0,
      extra_charges_total: EDIT ? x.extra_charges_total : 0,
      extra_charges_base: EDIT ? x.extra_charges_base : 0,
    }));

    setExtraCharges(updatedData);
  }

  const handleUpdateExtraCharge = (index: number, field: string, value) => {
    setExtraCharges(prevExtraCharges => {
      const updatedExtraCharges = [...prevExtraCharges];
      updatedExtraCharges[index][field] = value;

      const EXTRA_CHARGE_BASE = EDIT
        ? updatedExtraCharges[index].extra_charges_area > 0
          ? updatedExtraCharges[index].extra_charges_area *
            updatedExtraCharges[index].extra_charges_rate
          : updatedExtraCharges[index].extra_charges_rate
        : updatedExtraCharges[index].amount_type === 'ratebase_amt'
        ? updatedExtraCharges[index].extra_charges_area *
          updatedExtraCharges[index].ratebase_amounts
        : updatedExtraCharges[index].fixed_amounts;
      const discountAmt = updatedExtraCharges[index].extra_charges_disc_amt;
      const totalAmount = EXTRA_CHARGE_BASE === 0 ? 0 : EXTRA_CHARGE_BASE - discountAmt;

      updatedExtraCharges[index].extra_charges_amt = totalAmount;

      return updatedExtraCharges;
    });
  };

  function handle_Extra_Charge_Row_Total() {
    setExtraCharges(prevList =>
      prevList?.map(x => {
        const Amt = EDIT
          ? x.extra_charges_area > 0
            ? Number(x.extra_charges_area) * x.extra_charges_rate
            : Number(x.extra_charges_rate)
          : x.amount_type === 'ratebase_amt'
          ? Number(x.extra_charges_area) * x.extra_charges_rate
          : x.extra_charges_rate;

        let extraChargesAmount;
        if (EDIT) {
          extraChargesAmount =
            x.extra_charges_distribution_method === 0 || x.extra_charges_distribution_method === '0'
              ? 0
              : Amt - x.extra_charges_disc_amt;
        } else {
          extraChargesAmount =
            x.extra_charges_distribution_method === '' ? 0 : Amt - x.extra_charges_disc_amt;
        }
        return {
          ...x,
          extra_charges_amt: extraChargesAmount,
        };
      }),
    );
  }

  const handleDeleteExtraCharge = (index: number) => {
    setExtraCharges(prevExtraCharges => {
      const updatedExtraCharges = [...prevExtraCharges];
      updatedExtraCharges.splice(index, 1);
      return updatedExtraCharges;
    });
  };

  const handleTotalExtraCharge = () => {
    let total = 0;
    extraCharges?.forEach(charge => {
      total += charge.extra_charges_amt || 0;
    });

    return Number(total).toFixed(2);
  };

  const handleExtraChargeAdd = () => {
    setExtraCharges([
      ...extraCharges,
      {
        extra_charges_no: extraCharges.length + 1,
        extra_charges_title: '',
        extra_charges_distribution_method: '',
        extra_charges_area: 0,
        extra_charges_rate: 0,
        extra_charges_disc_amt: 0,
        extra_charges_disc_per: 0,
        extra_charges_amt: 0,
        extra_charges_base: 0,
        ratebase_amounts: '0',
        fixed_amounts: '0',
        amount_type: '',
      },
    ]);
  };

  // booking form submission
  const onSubmit = async values => {
    const {
      project_id,
      unit_id,
      broker_id,
      through_broker,
      brokerage,
      broker_remark,
      unit_reserved_date,
      calculation_method,
      basic_rate_no,
      basic_rate_description,
      basic_rate_area,
      basic_rate,
      basic_rate_disc_amt,
      basic_rate_disc_per,
      basic_rate_basic_amount,
      disc_remarks,
      gst_per,
      gst_amt,
      stampduty_per,
      stampduty_amount,
      reg_per,
      reg_amount,
      custom_payment_remark,
      custom_payment_total_amount,
      bank,
      loan_amt,
      installments,
      clp_installments,
      installment_type,
      custom_payment_remark_id,
      clp_bank_id,
      from_bank,
      from_customer,
      signature,
      is_verified_otp,
      is_verified_email_otp,
    } = values;

    await dispatch(
      addBooking({
        project_bookings_id: Number(project_bookings_temp_id) || 0,
        project_id,
        unit_id,
        visitors_id: EDIT ? values.visitors_id : customerDetails?.id,
        broker_id,
        through_broker: through_broker ? 'yes' : 'no',
        brokerage,
        broker_remark,
        unit_reserved_date,
        parking_no: PARKING_SWITCH ? parkingInfo?.map(i => i?.id).join(',') : values.parking_no,
        calculation_method:
          calculation_method === 'rate_base'
            ? 'rate_base'
            : calculation_method === 'clp_base'
            ? 'clp_base'
            : 'fixied_amt',
        basic_rate_no,
        basic_rate_description,
        basic_rate_area,
        basic_rate: basic_rate || 0,
        basic_rate_disc_amt,
        basic_rate_disc_per,
        basic_rate_basic_amount,
        other_charges: OCList?.length
          ? OCList
          : [
              {
                unit_other_charge_id: 0,
                other_charges_no: 0,
                other_charges_title: '',
                other_charges_distribution_method: '',
                other_charges_area: 0,
                other_charges_rate: 0,
                other_charges_disc_amt: 0,
                other_charges_disc_per: 0,
                other_charges_amount: 0,
              },
            ],
        other_charges_total: parseFloat(handleTotalOtherCharge()),
        sub_total_amt: basic_rate_basic_amount + parseFloat(handleTotalOtherCharge()),
        total_disc:
          parseFloat(handleTotalOtherDiscountAmt()) + parseFloat(values.basic_rate_disc_amt),
        disc_remarks: disc_remarks,
        gst_per,
        gst_amt,
        stampduty_per,
        stampduty_amount,
        reg_per,
        reg_amount,
        total_gove_tax: values.gst_amt + values.stampduty_amount + values.reg_amount,
        extra_charges: extraCharges.length
          ? extraCharges
          : [
              {
                extra_charges_no: '',
                extra_charges_title: '',
                extra_charges_distribution_method: '',
                extra_charges_area: '',
                extra_charges_rate: '',
                extra_charges_disc_per: '',
                extra_charges_disc_amt: '',
                extra_charges_amt: '',
              },
            ],
        extra_charges_total: parseFloat(handleTotalExtraCharge()),
        property_final_amount:
          parseFloat(values.basic_rate_basic_amount) +
          parseFloat(handleTotalOtherCharge()) +
          Number(values.gst_amt) +
          Number(values.stampduty_amount) +
          Number(values.reg_amount) +
          parseFloat(handleTotalExtraCharge()),
        is_loan: isToggle ? 'yes' : 'no',
        loan_amt,
        bank,
        loan_remarks: values.loan_remarks,
        installment_type,
        clp_installments,
        installments: installments.length
          ? installments
          : [
              {
                custom_payment_no: 0,
                custom_payment_installment: '',
                installment_due_date: '',
                installment_per: '',
                installment_basic_amt: 0,
                installment_otherchages_amt: 0,
                installment_extrachages_amt: 0,
                installment_amount: 0,
                gst: 0,
              },
            ],
        custom_payment_total_amount,
        custom_payment_remark_id,
        custom_payment_remark,
        is_verified_otp,
        is_verified_email_otp,
        ownership: values.ownership.length
          ? values.ownership
          : [
              {
                id: 0,
                ownership_customer_first_name: '',
                ownership_customer_phone: '',
                ownership_customer_email: '',
                ownership_customer_pan: '',
                ownership_customer_aadhar: '',
                ownership_customer_dl: '',
                ownership_customer_election: '',
                document_type: '',
                ownership_customer_passport: '',
                ownership_customer_dob: '',
                ownership_customer_address: '',
                ownership_son_of: '',
              },
            ],
        clp_bank_id,
        from_customer,
        from_bank,
        signature,
      }),
    ).then(() => {
      if (EDIT) {
        Cookies.set('_hjpreviewUnitUser_2492391', '');
        Cookies.set('_hjpreviewtower_idUser_2492391', '');
        Cookies.set('_hjbookingidUser_2492391', '');
        Cookies.set('_hjpreviewproject_floorUser_2492391', '');

        navigate('/bookingPreview', {
          state: { project_id, unitid: unit_id, bookingid: project_bookings_temp_id },
        });
        // navigate('/bookingApproval');

        return;
      }

      window.location.replace(OLD_SITE);
    });
  };

  const Schema = Yup.object({
    visitors_id: Yup.string().required('Customer is required'),
    ownership: Yup.array()
      .min(VALIDATION_REQUIRED_OWNERSHIP ? 1 : 0, 'Please add atleast one ownership to proceed')
      .of(
        Yup.object().shape({
          ownership_customer_first_name: Yup.string().required(
            `Ownership's first name is required`,
          ),
          ownership_customer_phone: Yup.string().when({
            is: () => OWNER_PHONE,
            then: schema =>
              schema
                .required('Ownership mobile number is required')
                .min(10, 'Please enter a valid mobile number')
                .matches(PHONE_REGEX, 'Please enter a valid phone number'),
          }),
          // ownership_customer_aadhar: Yup.string().when({
          //   is: () => OWNER_AADHAR,
          //   then: schema =>
          //     schema
          //       .required('Ownership Adhaar number is required')
          //       .matches(ADHAAR_REGEX, 'Please enter a valid Adhaar number'),
          // }),
          // ownership_customer_aadhar: Yup.string().matches(
          //   ADHAAR_REGEX,
          //   'Please enter a valid Adhaar number',
          // ),
          document_type: Yup.string().nullable(),
          ownership_customer_aadhar: Yup.string().when('document_type', {
            is: value => OWNER_AADHAR && value == 'Aadhar Card',
            then: schema =>
              schema
                .required('Ownership Aadhar number is required')
                .matches(ADHAAR_REGEX, 'Please enter a valid Aadhar number'),
            otherwise: schema => schema.notRequired(),
          }),
          ownership_customer_dl: Yup.string().when('document_type', {
            is: value => OWNER_DL && value == 'Driving License',
            then: schema => schema.required('Ownership Driving License is required'),
            otherwise: schema => schema.notRequired(),
          }),
          // ownership_customer_dl: Yup.string(),
          // ownership_customer_election: Yup.string().when({
          //   is: () => OWNER_ELECTION,
          //   then: schema => schema.required('Ownership Election number is required'),
          // }),
          ownership_customer_election: Yup.string().when('document_type', {
            is: value => OWNER_ELECTION && value == 'Election Card',
            then: schema => schema.required('Ownership Election number is required'),
            otherwise: schema => schema.notRequired(),
          }),
          // ownership_customer_election: Yup.string(),
          ownership_customer_pan: Yup.string().when({
            is: () => OWNER_PAN,
            then: schema =>
              schema
                .required('Ownership Pan number is required')
                .matches(PAN_REGEX, 'Please enter a valid PAN number'),
          }),
          // ownership_customer_pan: Yup.string().matches(
          //   PAN_REGEX,
          //   'Please enter a valid PAN number',
          // ),
          ownership_customer_passport: Yup.string().matches(
            PASSPORT_REGEX,
            'Please enter a valid Passport number',
          ),
          ownership_customer_address: Yup.string().when({
            is: () => OWNER_ADDRESS,
            then: schema => schema.required(`Ownership's Address is required`),
          }),
          ownership_customer_dob: Yup.string().when({
            is: () => OWNER_DOB,
            then: schema => schema.required(`Ownership's DOB is required`),
          }),
          ownership_son_of: Yup.string().required(`Ownership's Father name is required`),
          ownership_customer_email: Yup.string().when('id', {
            is: () => OWNER_EMAIL,
            then: schema => schema.required(`Ownership's Email is required`),
          }),
        }),
      ),
    // clp_bank_id: PERCENTAGE_CLASSIFICATION_REQUIRED
    //   ? Yup.number().required('Please select percentage classification')
    //   : null,
    installment_type: Yup.string(),
    from_customer: Yup.string()
      .when('installment_type', {
        is: (value: string) => value === 'clp_installments' && PERCENTAGE_CLASSIFICATION_REQUIRED,
        then: schema => schema.required('Classification is required field'),
      })
      .nullable(),
    through_broker: Yup.string(),
    broker_id: Yup.string()
      .nullable()
      .when('through_broker', {
        is: (value: string) => value === 'true',
        then: schema =>
          schema.required('You`ve selected through broker, please provide broker details.'),
      }),
    gst_per: Yup.number()
      .when({
        is: () => GOVERNMENT_REQUIRED,
        then: schema =>
          schema
            .required('Please add GST percentage')
            .test('positive', 'Please add GST percentage', value => value > 0),
      })
      .nullable(),
    gst_amt: Yup.number()
      .when({
        is: () => GOVERNMENT_REQUIRED,
        then: schema =>
          schema
            .required('Please add GST amount')
            .test('positive', 'Please add GST amount', value => value > 0),
      })
      .nullable(),
    stampduty_per: Yup.number()
      .when({
        is: () => GOVERNMENT_REQUIRED,
        then: schema =>
          schema
            .required('Please add Stampduty percentage')
            .test('positive', 'Please add Stampduty percentage', value => value > 0),
      })
      .nullable(),
    stampduty_amount: Yup.number()
      .when({
        is: () => GOVERNMENT_REQUIRED,
        then: schema =>
          schema
            .required('Please add Stampduty amount')
            .test('positive', 'Please add Stampduty amount', value => value > 0),
      })
      .nullable(),
    reg_per: Yup.number()
      .when({
        is: () => GOVERNMENT_REQUIRED,
        then: schema =>
          schema
            .required('Please add Registraion percentage')
            .test('positive', 'Please add Registraion percentage', value => value > 0),
      })
      .nullable(),
    reg_amount: Yup.number()
      .when({
        is: () => GOVERNMENT_REQUIRED,
        then: schema =>
          schema
            .required('Please add Registraion amount')
            .test('positive', 'Please add Registraion amount', value => value > 0),
      })
      .nullable(),
    signature: Yup.string()
      .when({
        is: () => e_SIGNATURE_REQUIRED,
        then: schema => schema.required('Please add customer signature.'),
      })
      .nullable(),
    installments: Yup.array().of(
      Yup.object().shape({
        installment_due_date: Yup.string().required(`Installments due date is required`),
      }),
    ),
  });

  const validate = () => {
    const errors: any = {};

    // , from_customer, from_bank

    const { installments, basic_rate_basic_amount, gst_amt, loan_amt = 0 } = values;

    const property_amount =
      Number(basic_rate_basic_amount || 0) +
      Number(gst_amt || 0) +
      Number(handleTotalOtherCharge() || 0);

    // const clp_bank_percent = Number(from_customer) + Number(from_bank);

    const totalPercentage = installments.reduce(
      (total, installment) => total + parseFloat(installment.installment_per || 0),
      0,
    );

    if (totalPercentage > 100 && values.installment_type === 'clp_installments') {
      errors.extraErrors = 'Installments total percentage cannot exceed more than 100%';
    }

    // if (clp_bank_percent < 100 && values.installment_type === 'clp_installments') {
    //   errors.from_customer = 'Percentage Classifications total percentage should be 100%';
    // }

    // if (clp_bank_percent > 100 && values.installment_type === 'clp_installments') {
    //   errors.from_customer = 'Percentage Classifications total percentage should be 100%';
    // }

    if (Number(loan_amt) > Number(property_amount)) {
      errors.bankLoanError = 'Loan amount cannot be greater than property amount';
    }

    return errors;
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: onSubmit,
    validationSchema: Schema,
    validateOnChange: false,
    validateOnBlur: false,
    validate: validate,
  });

  const { values, setFieldValue } = formik;

  // govt Taxes
  useEffect(() => {
    const { basic_rate_area = 0, basic_rate = 0, calculation_method } = values;

    const basic_rate_total = basic_rate_area * basic_rate;
    setBaseAmount(
      calculation_method === 'rate_base' || calculation_method === 'clp_base'
        ? basic_rate_total
        : basic_rate,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.basic_rate_area, values.basic_rate, values.calculation_method]);

  useEffect(() => {
    const { basic_rate_disc_amt = 0 } = values;

    if (
      values.calculation_method === 'rate_base' ||
      values.calculation_method === 'fixed_amount' ||
      values.calculation_method === 'clp_base'
    ) {
      setFieldValue(
        'basic_rate_basic_amount',
        parseFloat((baseAmount - basic_rate_disc_amt).toFixed(2)),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseAmount, setFieldValue, values.basic_rate_disc_amt, values.basic_rate_disc_per]);

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      basic_rate:
        values.calculation_method === 'rate_base'
          ? unitAreaInfo?.rate_base_amt
          : unitAreaInfo?.fixed_amount || 0,
      basic_rate_disc_amt: 0,
      basic_rate_disc_per: 0,
      basic_rate_basic_amount: 0,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.calculation_method]);

  useEffect(() => {
    if (EDIT) {
      if (values.is_loan === 'yes') {
        setIsToggle(true);
      } else {
        setIsToggle(false);
      }
      formik.setValues({
        ...formik.values,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [EDIT]);

  const updateStatusToAvailable = async () => {
    dispatch(
      updateUnitStatusToAvailable({
        project_id,
        unit_id,
      }),
    ).then(() => {
      window.location.replace(OLD_SITE);
    });
  };

  window.addEventListener('popstate', function () {
    if (EDIT) {
      // Clear the cookies
      Cookies.set('_hjpreviewUnitUser_2492391', '');
      Cookies.set('_hjpreviewtower_idUser_2492391', '');
      Cookies.set('_hjbookingidUser_2492391', '');
      Cookies.set('_hjpreviewproject_floorUser_2492391', '');

      navigate('/bookingPreview', {
        state: { project_id, unitid: unit_id, bookingid: project_bookings_temp_id },
      });
    }
  });

  return (
    <>
      <Loader loading={loading} />

      {/* Header */}
      <BookingHeader
        brokerDetails={brokerDetails}
        customerDetails={customerDetails}
        EDIT={EDIT}
        formikProps={formik}
        navigate={navigate}
        project_id={project_id}
        projectUnitStatus={projectUnitStatus}
        unit_id={unit_id}
        updateStatusToAvailable={updateStatusToAvailable}
      />

      <section className="booking-form-sec pt-0 bookingFormUpdated">
        <div className="booking-form-row">
          {/* 1st section */}
          <UnitInfo formikProps={formik} unit_id={unit_id} />

          {/* 2nd section */}
          <CustomerDetails
            customer_email={customer_email}
            customer_first_name={customer_first_name}
            customer_last_name={customer_last_name}
            customer_phone={customer_phone}
            customerDetails={customerDetails}
            EDIT={EDIT}
            formikProps={formik}
            inquiryPermission={inquiryPermission}
            project_id={project_id}
            setCustomerDetails={setCustomerDetails}
          />

          {/* 3rd section */}
          <BrokerDetails
            brokerDetails={brokerDetails}
            brokerPermission={brokerPermission}
            EDIT={EDIT}
            formikProps={formik}
            project_id={project_id}
            setBrokerDetails={setBrokerDetails}
            user_id={user_id}
          />

          {/* 4th section */}
          <Ownership EDIT={EDIT} formikProps={formik} ownershipPermission={ownershipPermission} />

          <Parking
            EDIT={EDIT}
            formikProps={formik}
            PARKING_SWITCH={PARKING_SWITCH}
            parkingInfo={parkingInfo}
            ParkingOption={ParkingOption}
          />

          {/* 5th section */}
          <CalculationMethod baseAmount={baseAmount} EDIT={EDIT} formikProps={formik} />

          {/* 6th section */}
          <OtherCharges
            EDIT={EDIT}
            formikProps={formik}
            handleOCListChange={handleOCListChange}
            handleTotalOtherCharge={handleTotalOtherCharge}
            OCList={OCList}
          />

          {/* 7th section */}
          <OverallDiscount
            formikProps={formik}
            handleTotalOtherDiscountAmt={handleTotalOtherDiscountAmt}
          />

          {/* 8th section */}
          <GovtTaxes formikProps={formik} handleTotalOtherCharge={handleTotalOtherCharge} />

          {/* 9th section */}
          <ExtraCharges
            EDIT={EDIT}
            extraCharges={extraCharges}
            formikProps={formik}
            handle_Extra_Charge_Row_Total={handle_Extra_Charge_Row_Total}
            handleDeleteExtraCharge={handleDeleteExtraCharge}
            handleExtraChargeAdd={handleExtraChargeAdd}
            handleTotalExtraCharge={handleTotalExtraCharge}
            handleUpdateExtraCharge={handleUpdateExtraCharge}
          />

          {/* 10th section */}
          <Summary
            formikProps={formik}
            handleTotalExtraCharge={handleTotalExtraCharge}
            handleTotalOtherCharge={handleTotalOtherCharge}
            handleTotalOtherDiscountAmt={handleTotalOtherDiscountAmt}
          />

          {/* 11th section */}
          <Loan
            EDIT={EDIT}
            formikProps={formik}
            isToggle={isToggle}
            toggleLoanSwitch={toggleLoanSwitch}
          />

          {/* 12th section */}
          <InstallmentSelection
            EDIT={EDIT}
            extraCharges={extraCharges}
            formikProps={formik}
            handleTotalExtraCharge={handleTotalExtraCharge}
            handleTotalOtherCharge={handleTotalOtherCharge}
            handleTotalOtherDiscountAmt={handleTotalOtherDiscountAmt}
            OCList={OCList}
            project_id={project_id}
            tower_id={tower_id}
          />

          {/* 13th section */}
          <Terms
            brokerDetails={brokerDetails}
            customerDetails={customerDetails}
            edit={EDIT}
            formikProps={formik}
            OLD_SITE={OLD_SITE}
            project_id={project_id}
          />

          {/* 14th section */}
          <Signature edit={EDIT} formikProps={formik} />
        </div>
      </section>
    </>
  );
};

export default BookingForm;
