import { useFormik } from 'formik';
import { debounce } from 'lodash';
import { useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { addCustomer, countryCode, getVisitorsList } from 'redux/sales';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { PHONE_REGEX } from 'utils/constant';
import * as Yup from 'yup';
const AddCustomerModal = ({ show, handleClose, project_id }) => {
  const dispatch = useAppDispatch();

  const { countryCodeOption } = useAppSelector(s => s.sales);

  // customers options

  const countryCodeList = countryCodeOption?.map(e => ({
    label: `${e.phone_code} - ${e.country_name}`,
    value: e.id,
  }));

  useEffect(() => {
    dispatch(countryCode({ project_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = async values => {
    const { firstName, lastName, email, phone, phone_code } = values;

    await dispatch(
      addCustomer({
        project_id,
        first_name: firstName,
        last_name: lastName,
        email,
        phone: parseInt(phone, 10),
        inquiry_for: 2,
        priority: 'medium',
        occupation: 0,
        budget_from: 0,
        budget_to: 0,
        other_occupation: 0,
        phone_code: phone_code,
      }),
    );

    await dispatch(
      getVisitorsList({
        project_id,
        filter_mode: 'name',
        role: 'admin',
        page: 'all',
      }),
    );

    await handleClose();
    formik.resetForm();
  };

  const debouncedHandleSubmit = useRef(
    debounce(values => {
      handleSubmit(values);
    }, 500),
  ).current;

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      phone_code: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Required Field'),
      lastName: Yup.string().required('Required Field'),
      email: Yup.string().email('Invalid email address'),
      phone: Yup.string()
        .required('Required Field')
        .min(10, 'Please enter a valid mobile number')
        .matches(PHONE_REGEX, 'Please enter a valid Adhaar number'),
    }),
    onSubmit: values => {
      debouncedHandleSubmit(values);
    },
  });
  return (
    <Modal
      centered
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      show={show}
      size="lg"
      onHide={handleClose}
    >
      <Modal.Header className="justify-content-center">
        <Modal.Title>
          <b>Add Customer</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="shwan-form">
            <div className="booking-form-col-6 border-0" id="showfirstbox">
              <div className="form-group">
                <div className="form-row newuser">
                  <div className="form-group col form-col-gap">
                    <label className="mandate-star mr-3">First Name</label>
                    <input
                      className="form-control"
                      name="firstName"
                      type="text"
                      value={formik.values.firstName}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.firstName && formik.errors.firstName && (
                      <div className="text-danger">{formik.errors.firstName}</div>
                    )}
                  </div>
                  <div className="form-group col">
                    <label className="mandate-star mr-3">Last Name</label>
                    <input
                      className="form-control"
                      name="lastName"
                      type="text"
                      value={formik.values.lastName}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.lastName && formik.errors.lastName && (
                      <div className="text-danger">{formik.errors.lastName}</div>
                    )}
                  </div>
                </div>
                <div className="form-row newuser">
                  <div className="form-group col form-col-gap">
                    <label>Email</label>
                    <input
                      className="form-control"
                      name="email"
                      type="email"
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="text-danger">{formik.errors.email}</div>
                    )}
                  </div>
                  <div className="form-group col">
                    <label className="mandate-star mr-3">Phone</label>
                    <div className="d-flex">
                      <div className="col-4 pr-1 pl-0">
                        <div>
                          <Select
                            name="phone_code"
                            options={countryCodeList}
                            styles={{
                              valueContainer: (provided: any) => ({
                                ...provided,
                                height: '50px',
                              }),
                              control: (provided: any) => ({
                                ...provided,
                                borderColor: 'rgba(4, 29, 54, 0.1)',
                                borderWidth: '2px',
                                boxShadow: 'unset',
                              }),
                            }}
                            // value={{ value: formik.values.phone_code }}
                            onChange={selectedOption => {
                              formik.setFieldValue('phone_code', selectedOption.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-8 pr-0 pl-2">
                        <input
                          className="form-control"
                          maxLength={10}
                          name="phone"
                          type="text"
                          value={formik.values.phone}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                    {formik.touched.phone && formik.errors.phone && (
                      <div className="text-danger">{formik.errors.phone}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-row justify-content-end mr-5 mb-3 ">
            <button
              className="Btn btn-lightblue-primary lbps-btn py-2 px-4 mr-4"
              type="button"
              onClick={handleClose}
            >
              Close
            </button>
            <button className="Btn btn-lightblue-primary  py-2 px-4" type="submit">
              Add
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddCustomerModal;
